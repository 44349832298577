export default defineNuxtRouteMiddleware((_to, _from) => {
  const layoutStore = useLayoutStore()
  const authStore = useAuthStore()

  layoutStore.getPlatform()
  if (process.server) {
    if (_to.path === '/landing/minecraft') {
      // Get request headers
      const headers = useRequestHeaders()
      // Extract the token, removing "Bearer " if present
      let token = headers?.['X-App-Token'] || headers?.['x-app-token'] || null
      if (token?.startsWith('Bearer ')) {
        token = token.split(' ')[1] // Mantener solo el valor del token
      }

      // Extract country code if available
      const countryCode = headers?.['X-App-Country'] || headers?.['x-app-country'] || null
      // Store token and country code if they exist
      if (token) {
        authStore.setToken(token) // Store the token in the store
      }
      if (countryCode) {
        authStore.setCountry(countryCode) // Store the country in the store
      }

      // Redirect to home if no token is found and the platform is 'web'
      if (!token && layoutStore.platform === 'web') {
        return navigateTo('/')
      }
    }
  }
})
